<div class="side-menu flex-lg-column me-lg-1 ms-lg-0">
    <!-- LOGO -->
    <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark" title="Accueil">
            <span class="logo-sm">
                <img src="assets/images/logo.png" alt="colocation" height="60">
            </span>
        </a>


        <a class="back" href="{{host}}" ><i class="ri-arrow-left-line"></i> retour</a>
    </div>
    <!-- end navbar-brand-box -->

    <!-- Start side-menu nav -->
    <div class="flex-lg-column my-auto">
        <ul class="nav nav-pills side-menu-nav justify-content-center" role="tablist">

            <li class="nav-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Messagerie">
                <a class="nav-link active" id="pills-chat-tab" data-bs-toggle="pill" href="#pills-chat" role="tab">
                    <i class="ri-message-3-line"></i>
                </a>
            </li>



            <li class="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <img [src]="imageCurrentUser" alt="" [title]="title" class="profile-user rounded-circle">
                </a>


            </li>


        </ul>
    </div>
    <!-- end side-menu nav -->

    <div class="flex-lg-column d-none d-lg-block">
        <ul class="nav side-menu-nav justify-content-center">


            <li class="nav-item btn-group dropup profile-user-dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <img [src]="imageCurrentUser" [title]="title" alt="" class="profile-user rounded-circle">
                </a>


            </li>

        </ul>
    </div>

</div>