import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { ConversationComponent } from "./conversation/conversation.component";

import { CommonModule } from "@angular/common";
import { ContactsComponent } from "./contacts/contacts.component";
import { DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "../app-routing.module";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [ConversationComponent, ContactsComponent],
  imports: [CommonModule, AppRoutingModule, RouterModule, FormsModule],
  providers: [DatePipe],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ContactsComponent],
})
export class ChatModule {}
