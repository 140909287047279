import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from "@angular/core"
import { Router } from "@angular/router"
import { ChatService } from "src/app/services/chat.service"
import { HideUserChat } from "src/app/shared/hideUserChat"
import { ConversationData } from "../conversation/conversation.model"
import { FavorieData } from "./favorie.model"
import { DatePipe } from "@angular/common"
import { User } from "src/app/shared/User"
import { GatewayWebsocketService } from "src/app/services/gateway-websocket/gateway-websocket.service"
import { environment } from "src/environments/environment"
import { UserService } from "src/app/services/user.service"

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  idServiceClient = environment.idServiceClient
  host = environment.host

  isServiceClient = true
  size
  hideUserChatValue = false
  filterContactValue
  currentUserId;

  listUsersConnected = []
  // userData: UserData = new UserData();
  lisContacts: ConversationData[]
  lisFavoris: FavorieData[]
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth
    this.size = event.target.innerWidth
  }
  constructor(
      private hideUserChat: HideUserChat,
      private chatService: ChatService,
      private router: Router,
      public datepipe: DatePipe,
      private user: User,
      protected userService: UserService,
      private gatewayWebsocketService: GatewayWebsocketService
  ) { }

  ngOnInit(): void {
    this.size = window.innerWidth
    this.user.currentUser.subscribe(async (user) => {
      if (user["id"]) {
        this.currentUserId = user["id"]
        this.isServiceClient = (this.idServiceClient == this.currentUserId)
        this.addUser()
        this.getUsers()
        this.getUnreadMessages()
        this.newMessageRecieved()
      }
    })
  }

  supportClientLastMessage:ConversationData=null

  loadContacts() {
    if(this.currentUserId) {
   
    this.chatService.loadContacts(this.currentUserId).subscribe((data: any) => {
      this.lisContacts = data?.contacts
      this.lisContacts.map((contact, index) => {
       
        if(contact.id_expediteur==this.idServiceClient || this.idServiceClient ==contact.id_destinataire){
          this.supportClientLastMessage=contact
        }

        if (contact.id_expediteur !== this.currentUserId) {
          contact.otherPersonIdAnnonce = contact.expediteur.annonce.id
          this.listUsersConnected.map((userConnected) => {
            if (userConnected.userId === contact.id_expediteur) {
              contact.connected = true
            }
          })
        } else if (contact.id_destinataire !== this.currentUserId) {
          contact.otherPersonIdAnnonce = contact.destinataire.annonce.id

          this.listUsersConnected.map((userConnected) => {
            if (userConnected.userId === contact.id_destinataire) {
              contact.connected = true
            }
          })
        } else {
          contact.connected = false
        }
      })

      this.lisFavoris = data?.favories

      this.lisFavoris.map((favorie, index) => {
        if (favorie.id_membre_to !== this.currentUserId) {

          this.listUsersConnected.map((userConnected) => {
            if (userConnected.userId === favorie.id_membre_to) {
              favorie.connected = true
            }
          })
        } else {
          favorie.connected = false
        }
      })
    })
    }
  }
  loadConversation(contactId) {
    this.navigateToConversation(contactId)
  }
  loadConversationCustomerSupport() {
    this.loadConversation(
      this.idServiceClient
      // null,
      // "Service Client",
      // null,
      // "assets/images/customer-support.png"
    )
  }

  getContenuNoHtml(contenu): string {
    const regex = /<[^>]*>/g;
    return contenu.replace(regex, '');
  }

  navigateToConversation(contactId) {
    if (this.size <= 768) {
      this.hideUserChat.changeStateHideUserChat(true)
    } else {
      this.hideUserChat.changeStateHideUserChat(false)
    }
    this.router.navigate(["/conversation/" + contactId], {})
  }
  filterContact(event: Event) {
    this.chatService
      .loadContacts(this.currentUserId, event)
      .subscribe((data: any) => {
        this.lisContacts = data?.contacts

        this.lisFavoris = data?.favories
      })
  }
  isToday(someDate) {
    const today = new Date()

    const someDateFormated = new Date(someDate)
    return (
      someDateFormated.getDate() == today.getDate() &&
      someDateFormated.getMonth() == today.getMonth() &&
      someDateFormated.getFullYear() == today.getFullYear()
    )
  }

  //listening to incoming msg real time
  newMessageRecieved() {
    this.gatewayWebsocketService.listen("getMessage").subscribe((data) => {
      this.loadContacts()
    })
  }
  //adduser   real time
  addUser() {
    if(this.currentUserId)
    this.gatewayWebsocketService.emit("addUser", this.currentUserId)
  }
  //getUsers  real time
  getUsers() {
    // this.loadContacts()
    this.gatewayWebsocketService
      .listen("getUsers")
      .subscribe((usersConnected: any) => {
        this.listUsersConnected = usersConnected
        this.gatewayWebsocketService.changeListUsersConnected(this.listUsersConnected)
        this.loadContacts()
      })
  }
  getUnreadMessages() {
    this.gatewayWebsocketService
      .listen("getUpdateMessageUnread")
      .subscribe((data) => {
        this.loadContacts()
      })
  }
}
