import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class NumericIdGuard implements CanActivate {

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const id: string = next.params.id;
        const isNumeric = /^\d+$/.test(id);

        if (!isNumeric) {
            console.error('L\'ID n\'est pas numérique.');
            return false;
        }

        return true;
    }
}