import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  loadContacts(id, filter?) {
    
    let urlRequest = this.apiUrl + "contacts?id=" + id;
    if (filter) {
      return this.http.get(urlRequest + "&filter=" + filter);
    }

    return this.http.get(urlRequest);
  }
  loadConversation(id, contactId) {
    return this.http.get(
      this.apiUrl + "conversation?id=" + id + "&contactId=" + contactId
    );
  }
  addMessage(data) {
    return this.http.post(this.apiUrl + "message", data);
  }
  deleteConversation(id, contactId) {
    return this.http.post(this.apiUrl + "conversation/delete/" + id, {
      contactId: contactId,
    });
  }
  blockContact(data) {
    return this.http.post(this.apiUrl + "block", data);
  }
  updateMessageUnread(id, contactId) {
    return this.http.post(this.apiUrl + "conversation/update/" + id, {
      contactId: contactId,
    });
  }
  sendEmail(data) {
    return this.http.post(this.apiUrl + "sendEmail", data);
  }
  getAnnonceId(contactId) {
    return this.http.get(this.apiUrl + "annonce/" + contactId);
  }
}
