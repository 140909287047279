<app-header></app-header>

<div class="layout-wrapper d-lg-flex">

    <!-- Start left sidebar-menu -->
    <ng-container *ngIf="!hideUserChatValue">

        <app-sidebar></app-sidebar>
    </ng-container>


    <!-- end left sidebar-menu -->

    <!-- start chat-leftsidebar -->

    <div class="chat-leftsidebar me-lg-1 ms-lg-0">

        <div class="tab-content" style="height: 100% !important;">

            <div class="tab-pane fade show active" id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab">

                <app-contacts #contacts></app-contacts>

            </div>

        </div>
        <!-- end tab content -->

    </div>


    <!-- end chat-leftsidebar -->
    <div class="user-chat w-100 overflow-hidden user-chat-show" [class.user-chat-show]="hideUserChatValue">


        <router-outlet></router-outlet>



    </div>


    <!-- modal of delete conversation -->

    <div class="modal fade" id="deleteModal" tabindex="-1" aria-hidden="true" #modal>
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="text-center p-4">


                        <h5 style="word-wrap: break-word;">Êtes-vous sûr de vouloir
                            supprimer cette conversation ?</h5>


                        <div class="mt-5">
                            <ul class="list-inline mb-1">
                                <li class="list-inline-item px-2 me-2 ms-0">
                                    <button type="button" class="btn btn-danger avatar-sm rounded-circle"
                                        data-bs-dismiss="modal">
                                        <span class="avatar-title bg-transparent font-size-20">
                                            <i class="ri-close-fill"></i>
                                        </span>
                                    </button>
                                </li>
                                <li class="list-inline-item px-2">
                                    <button type="button" (click)="deleteConversation()"
                                        class="btn btn-success avatar-sm rounded-circle">
                                        <span class="avatar-title bg-transparent font-size-20">
                                            <i class="ri-check-fill"></i>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- modal of block contact -->
    <div class="modal fade" id="blockModel" tabindex="-1" aria-hidden="true" #modal2>
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="text-center p-4">


                        <h5 style="word-wrap: break-word;">

                            Voulez-vous vraiment bloquer ce contact ?</h5>


                        <div class="mt-5">
                            <ul class="list-inline mb-1">
                                <li class="list-inline-item px-2 me-2 ms-0">
                                    <button type="button" class="btn btn-danger avatar-sm rounded-circle"
                                        data-bs-dismiss="modal">
                                        <span class="avatar-title bg-transparent font-size-20">
                                            <i class="ri-close-fill"></i>
                                        </span>
                                    </button>
                                </li>
                                <li class="list-inline-item px-2">
                                    <button type="button" (click)="blockContact()"
                                        class="btn btn-success avatar-sm rounded-circle">
                                        <span class="avatar-title bg-transparent font-size-20">
                                            <i class="ri-check-fill"></i>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- modal of report  -->
    <!-- Start add group Modal -->
    <div class="modal fade" id="reportModal" tabindex="-1" #modal3 role="dialog"
        aria-labelledby="addgroup-exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">Signaler cette personne à
                        l’administrateur </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        (click)="raison=''">
                    </button>
                </div>
                <div class="modal-body p-4">



                    <div class="mb-3">
                        <label for="addgroupdescription-input" class="form-label">Quel en est le motif ?</label>
                        <textarea class="form-control" [(ngModel)]="raison" id="addgroupdescription-input" rows="3"
                            placeholder="C’est à vous…   "></textarea>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"
                        (click)="raison=''">Fermer</button>
                    <button type="button" class="btn btn-primary" (click)="sendEmail()">Envoyer</button>
                </div>
            </div>
        </div>
    </div>
</div>