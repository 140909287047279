import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class UserService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, public datepipe: DatePipe) { }

  // loadUser(id) {
  //   return this.http.get(this.apiUrl + 'currentUser');
  // }
  loadCurrentUser() {
    return this.http.get(this.apiUrl + "currentUser", );
  }
  getUser(id) {
    return this.http.get(this.apiUrl + "getUser/" + id);
  }

  getImageProfilPath(sexe, photo_profil, is_fast?: string | undefined) {
    if(is_fast) {
      return "https://www.colocation-adulte.fr/avatar/maison.png";
    } else if (sexe === "F" && (photo_profil == "" || photo_profil == null)) {
      return "https://www.colocation-adulte.fr/img/merge/PhotoFemmeMessage-100px.gif";
    } else if (sexe === "H" && (photo_profil == "" || photo_profil == null)) {
      return "https://www.colocation-adulte.fr/img/merge/PhotoHommeMessage-100px.gif";
    } else if (sexe === "C" && (photo_profil == "" || photo_profil == null)) {
      return "https://www.colocation-adulte.fr/img/merge/PhotoCoupleMessage-100px.gif";
    }else {
      if (environment.AMAZONAWS_FOLDER == "")
        return (
          "https://s3.eu-west-3.amazonaws.com/pic-stockage/thumbnail/" +
          photo_profil +
          "?" +
          this.datepipe.transform(new Date(), "yyyy/MM/dd 23:59:00", "fr")
        );
      else
        return (
          "https://s3.eu-west-3.amazonaws.com/pic-stockage/" + environment.AMAZONAWS_FOLDER + "/thumbnail/" +
          photo_profil +
          "?" +
          this.datepipe.transform(new Date(), "yyyy/MM/dd 23:59:00", "fr")
        );
    }
  }

}
