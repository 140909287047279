import { ContactData } from "../contacts/contact.model";

export class ConversationData {
  is_fast_connect?: boolean;
  date_lecture?: any;
  constructor() { }
  contenu: string;
  id?: number;
  expediteur?: ContactData;
  id_expediteur?: number;
  destinataire?: ContactData;
  id_destinataire?: number;
  lu_destinataire?: number;
  lu_expediteur?: number;
  etat_expediteur?: number;
  etat_destinataire?: number;
  date_envoi?: Date;
  titre?: string;
  express?: number;
  deleted_recipient?: number;
  connected?: boolean
  otherPersonIdAnnonce?: number;
  express_resp_send?: number;
  deleted_sender?: number;
}
