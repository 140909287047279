import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactsComponent } from "src/app/chat/contacts/contacts.component";
import { ChatService } from "src/app/services/chat.service";
import { GatewayWebsocketService } from "src/app/services/gateway-websocket/gateway-websocket.service";
import { HideUserChat } from "src/app/shared/hideUserChat";
import { User } from "src/app/shared/User";
import { UserService } from "../../services/user.service";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  @ViewChild("modal") modal: ElementRef;
  @ViewChild("modal2") modal2: ElementRef;
  @ViewChild("modal3") modal3: ElementRef;
  @ViewChild("contacts") contacts: ContactsComponent;
  size;
  hideUserChatValue = false;
  contactId;
  id;
  ids;
  raison;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.size = event.target.innerWidth;
  }
  constructor(
    private hideUserChat: HideUserChat,
    private router: Router,
    private user: User,
    private userService: UserService,
    private route: ActivatedRoute,
    private chatService: ChatService,
    private gatewayWebsocketService: GatewayWebsocketService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.user.loadUser();
    this.user.currentUser.subscribe(async (user) => {
      this.id = user["id"];
      if(this.router.url === "/") {
        this.chatService.loadContacts(this.id).subscribe((data: any) => {
          let lisContacts = data?.contacts;
          if(lisContacts.length > 0) {
            this.router.navigate(['/conversation', (lisContacts[0].id_expediteur===this.id) ?
                lisContacts[0].id_destinataire : lisContacts[0].id_expediteur]);
          }
        });
      }
    });

    this.size = window.innerWidth;
    if (this.size <= 768 && this.router.url !== "/") {
      this.hideUserChat.changeStateHideUserChat(true);
    } else {
      this.hideUserChat.changeStateHideUserChat(false);
    }

    this.hideUserChat.currentHideUserChat.subscribe((data) => {
      this.hideUserChatValue = data;
    });
    this.contactBlocked();

  }

  deleteConversation() {
    this.route.firstChild.paramMap.subscribe((params) => {
      this.contactId = params.get('id');
    });
    this.chatService
      .deleteConversation(this.id, this.contactId)
      .subscribe((_) => {
        this.modal.nativeElement.click();
        this.contacts.loadContacts();
        this.router.navigate([""]);
        if (this.size <= 768) {
          this.hideUserChat.changeStateHideUserChat(false);
        }
      });
  }
  blockContact() {
    this.route.firstChild.paramMap.subscribe((params) => {
      this.contactId = params.get('id');
    });
    this.chatService
      .blockContact({
        date_block: new Date(),
        id_membre_from: this.id,
        id_membre_to: parseInt(this.contactId),
      })
      .subscribe((_) => {
        this.blockUserWb(this.id, parseInt(this.contactId), "ok");
        this.modal2.nativeElement.click();
        this.contacts.loadContacts();
        this.router.navigate([""]);

        if (this.size <= 768) {
          this.hideUserChat.changeStateHideUserChat(false);
        }
      });
  }
  sendEmail() {
    this.route.firstChild.paramMap.subscribe((params) => {
      this.contactId = params.get('id');
    });
    this.userService.getUser(this.contactId).subscribe((user) => {
      let dataToSend = {
        nom: user["nom"] ? user["nom"] : null,
        prenom: user["prenom"] ? user["prenom"] : user["pseudo"],
        raison: this.raison,
      };
      this.chatService.sendEmail(dataToSend).subscribe(
        (_) => {
          this.modal3.nativeElement.click();
          this.raison = "";
          this.notifSucces();
        },
        (error) => {
          this.notifFailed();
        }
      );
    });
  }
  //block user with realtime
  blockUserWb(senderId: number, receiverId: number, text: string) {
    this.gatewayWebsocketService.emit("blockContact", {
      senderId,
      receiverId,
      text,
    });
  }
  //listening to incoming blocked contact realtime
  contactBlocked() {
    this.gatewayWebsocketService.listen("getBlockContact").subscribe((data) => {
      this.router.navigate([""]);
      this.contacts.loadContacts();
    });
  }
  notifSucces() {
    this.toastr.success("Succès", "Message Envoyé");
  }
  notifFailed() {
    this.toastr.error("Échouer", "Message non envoyé Veuillez réessayer!", {
      timeOut: 3000,
    });
  }
}
