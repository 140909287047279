import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HideUserChat {
  private hideUserChat = new BehaviorSubject(false);
  currentHideUserChat = this.hideUserChat.asObservable();

  constructor() {}
  changeStateHideUserChat(state: boolean) {
    this.hideUserChat.next(state);
  }
}
