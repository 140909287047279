import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = request.headers;
        const modifiedHeaders = headers.set('Cache-Control', 'no-cache');

        // Cloner la requête avec les nouveaux en-têtes
        const modifiedRequest = request.clone({ headers: modifiedHeaders, withCredentials: true });

        // Passer la requête modifiée au prochain gestionnaire
        return next.handle(modifiedRequest);
    }
}