import { HostListener, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class User {
  private user = new BehaviorSubject({
    //id: parseInt(localStorage.getItem("idUser")),
    id: null,
    nom: null,
    prenom: null,
    sexe: null,
    image: null,
  });
  currentUser = this.user.asObservable();

  constructor(private userService: UserService) {}
  async loadUser() {
    return this.userService.loadCurrentUser().subscribe((data: any) => {
      //data = {id:199474, nom: 'christophe', pseudo: 'buse974'}
      if (!data.id) {
        console.log("NO ID", data);
        window.location.href = "/";
      } else {
        //localStorage.setItem("idUser", data.id);
        return this.user.next({
          id: parseInt(data.id),
          nom: data.nom ? data.nom : data.pseudo,
          prenom: data.prenom,
          sexe: data.sexe,
          image: data.photo_profil,
        });
      }
    });
  }
}
