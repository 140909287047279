import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GatewayWebsocketService {
  apiUrl = environment.apiUrl;
  public listUserConnected = new BehaviorSubject([]);
  currentListUserConnected = this.listUserConnected.asObservable();
  socket: any;
  constructor() {
    console.log(environment.socketHost);
    this.socket = (io as any)(environment.socketHost, { path: environment.pathSocket, withCredentials: true });
  }
changeListUsersConnected(state:any) {
  this.listUserConnected.next(state)


}
  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }
  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }
}
