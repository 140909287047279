import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ConversationComponent } from "./chat/conversation/conversation.component";

import { MainComponent } from "./core/main/main.component";
import {NumericIdGuard} from "./guards/numeric-id.guard";
const routes: Routes = [
  { path: "", redirectTo: "/", pathMatch: "full" },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "conversation/:id",
        component: ConversationComponent,
      },
      {
        path: ':id',
        component: ConversationComponent,
        canActivate: [NumericIdGuard]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule],
})
export class AppRoutingModule {
}
